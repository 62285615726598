module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f2f2f2',

  primary: '#D42B30',
  primaryLight: '#D42B30',
  primaryDark: '#971A18',

  secondary: '#707070',
  secondaryLight: '#707070',
  secondaryDark: '#0D0D0D',
};
